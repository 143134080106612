import diamondBg from '../assets/images/status-5-bg.webp'
import platinumBg from '../assets/images/status-4-bg.webp'
import orangeTomo from '../assets/images/orange_tomo.png'
import silverTomo from '../assets/images/silver_tomo.png'
import goldTomo from '../assets/images/gold_tomo.png'
import platinumTomo from '../assets/images/platinum_tomo.png'
import diamondTomo from '../assets/images/diamond_tomo.png'
import legendTomo from '../assets/images/legend_tomo.png'

export type RankType = 'Silver' | 'Gold' | 'Diamond' | 'Platinum' | 'Legend' | 'Orange'

type RankColorType = {
  bg: string
  bgSecondary: string
  bgTertiary: string
  primary: string
  primaryAccent: string
  primaryDark: string
  secondary: string
  secondaryAccent: string
  secondaryDark: string
  tertiary: string
  tertiaryAccent: string
  secondaryAccentLight: string
  tertiaryText: string
  secondaryText: string
}

type RankColorsType = {
  [key in RankType]: RankColorType
}

export const rankColors: RankColorsType = {
  Orange: {
    bg: 'transparent linear-gradient(180deg, #FFA500 0%, #FFD700 0%, #FFA500 100%) 0% 0% no-repeat padding-box',
    primary: '#EFEFEF',
    primaryDark: '#656565',
    primaryAccent: '#000000',
    secondary: '#D4EAE9',
    secondaryAccent: '#67A5A3',
    tertiary: '#080808',
    bgSecondary: '#67A5A3',
    secondaryDark: '#67A5A3',
    secondaryAccentLight: '#F3F9F8',
    bgTertiary: '#FFA500',
    tertiaryAccent: '#81531AFF',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  },
  Silver: {
    bg: 'transparent linear-gradient(180deg, #bababa 0%, #d7d7d7 11%, #ededed 15%, #fafafa 19%, #ffffff 22%, #f9f9f9 28%, #e9e9e9 35%, #cfcfcf 42%, #d1d1d1 57%, #e4e4e4 77%, #ffffff 84%, #f9f9f9 86%, #e9e9e9 90%, #cecece 94%, #aaaaaa 98%, #909090 100%) 0% 0% no-repeat padding-box',
    primary: '#EFEFEF',
    primaryAccent: '#000000',
    primaryDark: '#656565',
    secondary: '#D4EAE9',
    secondaryAccent: '#000000',
    secondaryDark: '#656565',
    tertiary: '#080808',
    bgSecondary: 'transparent linear-gradient(180deg, #bababa 0%, #d7d7d7 11%, #ededed 15%, #fafafa 19%, #ffffff 22%, #f9f9f9 28%, #e9e9e9 35%, #cfcfcf 42%, #d1d1d1 57%, #e4e4e4 77%, #ffffff 84%, #f9f9f9 86%, #e9e9e9 90%, #cecece 94%, #aaaaaa 98%, #909090 100%) 0% 0% no-repeat padding-box',
    secondaryAccentLight: '#67A5A3',
    bgTertiary: '#cfcfcf',
    tertiaryAccent: '#080808',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  },
  Gold: {
    bg: 'transparent linear-gradient(180deg, #E09D18 0%, #F9C924 0%, #E4AF18 32%, #FFF98C 68%, #FFD440 100%) 0% 0% no-repeat padding-box',
    primary: '#EFEFEF',
    primaryAccent: '#000000',
    primaryDark: '#C99B00',
    secondary: '#FFD95C',
    secondaryAccent: '#000000',
    tertiary: '#080808',
    secondaryDark: '#C99B00',
    bgSecondary: 'transparent linear-gradient(180deg, #E09D18 0%, #F9C924 0%, #E4AF18 32%, #FFF98C 68%, #FFD440 100%) 0% 0% no-repeat padding-box',
    secondaryAccentLight: '#C99B00',
    bgTertiary: '#FFD95C',
    tertiaryAccent: '#C99B00',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  },
  Diamond: {
    bg: `transparent url(${diamondBg}) 0% 0% no-repeat padding-box`,
    primary: '#EFEFEF',
    primaryAccent: '#000000',
    primaryDark: '#6ECCF7',
    secondary: '#CEF0FF',
    secondaryAccent: '#000000',
    tertiary: '#080808',
    secondaryDark: '#6ECCF7',
    bgSecondary: 'transparent url(${diamondBg}) 0% 0% no-repeat padding-box',
    secondaryAccentLight: '#6ECCF7',
    bgTertiary: '#CEF0FF',
    tertiaryAccent: '#6ECCF7',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  },
  Platinum: {
    bg: `transparent url(${platinumBg}) 0% 0% no-repeat padding-box`,
    primary: '#EFEFEF',
    primaryAccent: '#000000',
    primaryDark: '#6464B4',
    secondary: '#DADAFD',
    secondaryAccent: '#000000',
    tertiary: '#080808',
    secondaryDark: '#6464B4',
    bgSecondary: 'transparent url(${platinumBg}) 0% 0% no-repeat padding-box',
    secondaryAccentLight: '#6464B4',
    bgTertiary: '#DADAFD',
    tertiaryAccent: '#6464B4',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  },
  Legend: {
    bg: '#212121 0% 0% no-repeat padding-box',
    primary: '#EFEFEF',
    primaryDark: '#000000',
    primaryAccent: '#000000',
    secondary: '#000000',
    secondaryAccent: '#FFFFFF',
    tertiary: '#FFFFFF',
    secondaryDark: '#000000',
    bgSecondary: '#000000',
    secondaryAccentLight: '#000000',
    bgTertiary: '#080808',
    tertiaryAccent: '#080808',
    tertiaryText: '#080808',
    secondaryText: '#080808'
  }
}

export const rankNames: { [key in RankType]?: string } = {
  Orange: 'オレンジ',
  Silver: 'シルバー',
  Gold: 'ゴールド',
  Platinum: 'プラチナ',
  Diamond: 'ダイヤモンド',
  Legend: 'レジェンド'
}

export const rankNamesByIndex: { [key: number]: string } = {
  0: 'オレンジ',
  1: 'シルバー',
  2: 'ゴールド',
  3: 'プラチナ',
  4: 'ダイヤモンド',
}

export const rankImages: { [key in RankType]?: string } = {
  Orange: orangeTomo,
  Silver: silverTomo,
  Gold: goldTomo,
  Platinum: platinumTomo,
  Diamond: diamondTomo,
  Legend: legendTomo
}