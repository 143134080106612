import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import noImage from '../../assets/images/my-page-news-min.jpg';

interface NewsListingProps {
  title?: string;
  id?: string;
  image?: string;
  category?: string;
  post_date?: string;
  body?: string;
}

interface Category {
  id: string;
  name: string;
}

const NewsListing: React.FC<NewsListingProps> = (props) => {
  const truncatedBody = props.body && props.body.length > 101 ? `${props.body.substring(0, 100)}&hellip;` : props.body;
  const createMarkup = (htmlString: string) => {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, 'text/html');
    return parsedHtml.body;
  };
  
  const sanitizedTruncatedBody = truncatedBody ? createMarkup(truncatedBody) : undefined;
  
  return (
    <li className='mb-4 flex w-full items-stretch justify-between border bg-white border-rank-tertiaryAccent'>
      <div className='flex h-full flex-col gap-3 p-3 sm:gap-5 md:flex-row lg:gap-10'>
        <figure className='overflow-hidden md:w-72 lg:w-[300px] xl:w-[400px] xl:h-[220px]'>
          <img className='h-full w-full rounded-md object-cover' src={props.image || noImage} alt='' />
        </figure>
        <Link to={`/news/${props.id || ''}`} className='flex flex-1 flex-col gap-2 py-3'>
          <div>
            <span
              className='border px-2 py-1 text-xs text-rank-tertiaryText bg-rank-bgTertiary border-rank-tertiary sm:text-sm'>
              {props.category}
            </span>
            <span className='pl-2 text-xs text-rank-tertiaryText sm:text-sm'>{props.post_date}</span>
          </div>
          <h2 className='py-3 text-xl text-rank-primaryDark'>{props.title}</h2>
          {sanitizedTruncatedBody && (
            <div dangerouslySetInnerHTML={{ __html: sanitizedTruncatedBody.innerHTML }} />
          )}
        </Link>
      </div>
      <Link to={`/news/${props.id || ''}`} className='hidden items-center bg-rank-tertiaryAccent md:flex'>
        <div className='block'>
          <ChevronRightIcon className='h-10 w-10 fill-white' />
        </div>
      </Link>
    </li>
  );
};


export default NewsListing;