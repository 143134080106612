import React from 'react';

interface SwitchButtonProps {
  checked: boolean;
  toggleSwitch: () => void;
}

const SwitchButton: React.FC<SwitchButtonProps> = ({checked, toggleSwitch}) => {

  return (
    <button
      onClick={toggleSwitch}
      className={`relative inline-flex items-center h-6 w-12 xl:h-8 xl:w-16 transition-colors duration-300 ${
        checked ? 'bg-blue-500' : 'bg-gray-300'
      } rounded-full focus:outline-none`}
    >
      <span
        className={`inline-block h-4 w-4 xl:h-6 xl:w-6 transform bg-white rounded-full transition-transform duration-300 ${
          checked ? 'translate-x-6 xl:translate-x-8' : 'translate-x-1'
        }`}
      ></span>
    </button>
  );
};

export default SwitchButton;
