import React from 'react';
import { NavLink } from 'react-router-dom';

const navLinks = [
  {
    name: 'マイページトップ',
    link: '/',
  },
  {
    name: '出資状況',
    link: '/investment-status',
  },
  {
    name: '配当スケジュール',
    link: '/dividend-schedule',
  },
  {
    name: 'ファンド一覧',
    link: '/funds',
  },
];


export type NavLinkProps = {
  name: string;
  link: string;

};

const Link: React.FC<NavLinkProps> = ({ name, link }) => {
  return (
    <NavLink
      className={({ isActive }) => `lg:text-lg hidden md:block font-semibold hover:text-rank-tertiaryAccent ${isActive ? 'text-rank-tertiaryAccent' : 'text-primary-text'}`}
      to={link}
    >
      {name}
    </NavLink>
  );
};

export const NavLinks: React.FC = () => {
  return (
    <React.Fragment>
      {
        navLinks.map((value, index) => <Link key={index} {...value} />)
      }
    </React.Fragment>
  );
};