import React, { useState } from 'react';
import { DownArrow } from '../../components/icons/DownArrow';
import { UpArrow } from '../../components/icons/UpArrow';

export type SectionHeaderProp = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  arrowClassName?: string;
}

export const DropdownLayout: React.FC<SectionHeaderProp> = ({ title, className, children, arrowClassName }) => {
  const [showModel, setShowModel] = useState(true);

  const handleModelClick = () => {
    setShowModel(!showModel);
  };
  return (
    <div className='mt-4 px-1'>
      <div
        className={`${className} flex w-full items-center justify-between p-1 max-[450px]:px-3 cursor-pointer transition duration-1000 ease-in-out`}
        onClick={handleModelClick}>
        <p className='font-bold sm:pl-5 sm:text-xl'>{title}</p>
        <div className='items-end'>
            <span
              className={`flex items-center rounded-md py-2 pr-3 pl-5 text-sm font-bold text-white sm:py-3 sm:pl-10 sm:text-xl ${arrowClassName || ''}`}>
              {showModel ? (<UpArrow className='h-6 w-6 sm:h-8 sm:w-8' />) : (
                <DownArrow className='h-6 w-6 sm:h-8 sm:w-8' />)}
            </span>
        </div>
      </div>
      {showModel && (
        <div className='mt-4 mb-10 grid gap-5 transition duration-1000 ease-in-out sm:gap-10 lg:grid-cols-2'>
          {children}
        </div>
      )}
    </div>
  );
};