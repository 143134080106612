import React, { useReducer } from 'react';
import { defaultLoading, LoadingActionType, loadingReducer } from '../store/loading';
import LoadingComponent from '../components/ui/LoadingComponent';

type LoadinContextType = [
  {
    loading: boolean,
    activeLoading: number
  },
  React.Dispatch<LoadingActionType>
]

export const LoadingContext = React.createContext<LoadinContextType>(null!);

interface LoadingProviderProps {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, defaultLoading);

  return (
    <LoadingContext.Provider value={[state, dispatch]}>
      {
        state.loading &&
        <LoadingComponent />
      }
      {children}
    </LoadingContext.Provider>
  );
};
