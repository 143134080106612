import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

type BreadcrumbItemType = {
  url: string;
  name: string;
};

interface BreadcrumbItemProps extends BreadcrumbItemType {
  index: number;
}

interface BreadcrumbProps {
  items: BreadcrumbItemType[];
  className?: string;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({ url, name, index }) => {
  return (
    <li
      className='flex h-5 items-center pb-4 group'
      itemScope
      itemType='https://schema.org/ListItem'
      itemProp='itemListElement'
    >
      <span className='group-first:hidden'><ChevronRightIcon className='text-[#C7B396] h-5 w-5 font-bold' /></span>
      <Link to={url} className='text-xs hover:text-gray-700 sm:text-base lg:text-lg' itemProp='item'>
        <span itemProp='name'>{name}</span>
      </Link>
      <meta itemProp='position' content={index.toString()} />
    </li>
  );
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, className }) => (
  <nav className={`${className} border-b-8 border-rank-bgTertiary pt-12`}>
    <ol className='flex items-center flex-wrap justify-center sm:justify-start' itemScope
        itemType='https://schema.org/BreadcrumbList'>
      {items.map((item, index) => (
        <BreadcrumbItem key={index} {...item} index={index + 1} />
      ))}
    </ol>
  </nav>
);