import React, { useEffect, useState } from 'react';
import { NavLinks } from './Links';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HamburgerButton, NavMenu } from './Menu';
import Logo from './Logo';
import { BuildingOffice2Icon, CalendarDaysIcon, ChartPieIcon, HomeIcon } from '@heroicons/react/24/solid';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../db';
import { rankColors, rankImages, rankNames } from '../../../store/ranks';
import statusUpBg from '../../../assets/images/status_up_bg.png';
import pendingFundImg from '../../../assets/images/pending_fund.png';
import useAxios from '../../../hooks/useAxios';
import { BACKEND_URL } from '../../../store';
import { XMarkIcon } from '@heroicons/react/20/solid';

const { user } = db;

const Nav: React.FC = () => {
  const userData = useLiveQuery(() => user.get(1), []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(true)
  const location = useLocation();
  const axios = useAxios();

  useEffect(() => {
    const body = document.getElementById('main-body');
    if (isOpen && body) {
      body.style.overflow = 'hidden';
    } else if (!isOpen && body) {
      body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleNewStatusButtonClick = (name: 'close' | 'visit') => {
    axios.get('/api/user/new-status-viewed')
      .catch((error) => console.error(error))
      .finally(() => {
        if (name === 'close') {
          user.update(1, { newStatusIsViewed: true });
        } else {
          user.update(1, { newStatusIsViewed: true });
          window.location.href = `${BACKEND_URL}/rank-program/`;
        }
      })
  }
  return (
    <div className='sticky top-0 left-0 z-10'>
      {
        userData?.rank && userData?.newStatusIsViewed === false && (
          <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/60 z-50">
            <div className="bg-white p-4 rounded-xl text-center text-lg">
              <h2
                className="text-xl font-bold">{userData.rank === 'Orange' ? '今年はオレンジステータスです。' : `${rankNames[userData.rank]}ステータスにアップしました！`}</h2>
              <div style={{ backgroundImage: `url(${statusUpBg})`, backgroundPosition: 'center' }}
                   className="relative w-3/5 rounded-full my-2 mx-auto">
                <img src={statusUpBg} className="invisible" alt="hidden bg" />
                <img src={rankImages[userData.rank]} alt="new rank"
                     className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/2" />
              </div>
              {
                userData.rank === 'Orange' ? (
                  <>
                    <p>ステータスアップを目指して頑張りましょう！</p>
                    <p><span className="text-primary-dark">ステータス</span>が昇格すると特典を受けられます</p>
                  </>
                ) : (
                  <>
                    <p>おめでとうございます。</p>
                    <p>ステータスアップの条件を達成しましたので</p>
                    <p><span className="text-primary-dark">{rankNames[userData.rank]}ステータス</span>へ昇格しました。</p>
                    <div className="border border-primary-dark rounded-xl p-4 mx-auto my-4">
                      <p className="font-bold">トモタクポイント還元率</p>
                      <p><span className="text-primary-dark text-3xl font-bold">{userData.rankCashbackRate}</span>%</p>
                    </div>
                  </>
                )
              }
              <div className="flex gap-2 justify-center m-auto font-bold my-4">
                <button
                  type="button"
                  onClick={() => handleNewStatusButtonClick('visit')}
                  className="min-w-fit w-3/5 py-4 bg-primary-dark border border-primary-dark rounded-xl"
                >
                  プログラム特典を確認
                </button>
                <button
                  type="button"
                  onClick={() => handleNewStatusButtonClick('close')}
                  className="flex-1 p-4 bg-white border border-black rounded-xl"
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        )
      }
      <div className='w-full bg-[#F9F9F3] md:bg-white md:drop-shadow'>
        <div className='mx-auto flex h-20 items-center justify-between px-1 lg:container sm:px-4'>
          <Logo isOpen={isOpen} setIsOpen={setIsOpen}
                color={userData?.rank ? rankColors[userData.rank].tertiaryAccent : ''} />
          <div className='items-center gap-3 lg:gap-5 flex'>
            {!isOpen && <NavLinks />}
            <NavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
        <div
          className={`flex md:hidden w-full px-3 py-2 z-50 fixed bottom-0 left-0 border-gray-300 ${isOpen ? 'bg-transparent border-t-0' : 'bg-white border-t'}`}>
          <ul className={`container mx-auto flex items-center ${isOpen ? 'justify-end' : 'justify-between'}`}>
            <li className={`${isOpen ? 'hidden' : 'block'}`}>
              <NavLink to='/'
                       className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-rank-primaryDark' : 'text-primary-text text-opacity-50'}`}>
                <HomeIcon className='h-8 w-8 sm:h-10 sm:w-10' />
                <span className='text-xs font-bold sm:text-base'>TOP</span>
              </NavLink>
            </li>
            <li className={`${isOpen ? 'hidden' : 'block'}`}>
              <NavLink to='/investment-status'
                       className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-rank-primaryDark' : 'text-primary-text text-opacity-50'}`}>
                <ChartPieIcon className='h-8 w-8 sm:h-10 sm:w-10' />
                <span className='text-xs font-bold sm:text-base'>出資状況</span>
              </NavLink>
            </li>
            <li className={`${isOpen ? 'hidden' : 'block'}`}>
              <NavLink to='/funds'
                       className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-rank-primaryDark' : 'text-primary-text text-opacity-50'}`}>
                <BuildingOffice2Icon className='h-8 w-8 sm:h-10 sm:w-10' />
                <span className='text-xs font-bold sm:text-base'>ファンド</span>
              </NavLink>
            </li>
            <li className={`${isOpen ? 'hidden' : 'block'}`}>
              <NavLink to='/dividend-schedule'
                       className={({ isActive }) => `flex flex-col items-center ${isActive ? 'text-rank-primaryDark' : 'text-primary-text text-opacity-50'}`}>
                <CalendarDaysIcon className='h-8 w-8 sm:h-10 sm:w-10' />
                <span className='text-xs font-bold sm:text-base'>配当予定</span>
              </NavLink>
            </li>
            <li>
              <HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
            </li>
          </ul>
        </div>
      </div>
      {userData?.hasPendingApplication && !['/investment-status/', '/investment-status'].includes(location.pathname)
        && !location.pathname.includes('/investment-status/pending-list') && popUpIsOpen
        && (
          <div className="flex justify-center fixed h-full w-full z-40 top-0 bg-secondary-light/80 items-center">
            <div className='flex p-2 items-center justify-center rounded-md relative h-fit'>
              <img src={pendingFundImg} alt="pending fund"/>
              <Link 
                to={`/investment-status${userData.pendingApplicationId ? `/pending-list/${userData.pendingApplicationId}` : ''}`} 
                className='absolute bottom-4 sm:bottom-5 md:bottom-10 bg-primary-dark rounded text-white py-0.5 sm:py-2 px-8 sm:text-2xl'
              >
                ご確認はこちらへ
              </Link>
              <span className="absolute top-0 -right-0 text-primary-text bg-white rounded-full">
                <XMarkIcon
                  className="h-8 w-8 sm:h-14 sm:w-14 cursor-pointer"
                  onClick={() => {setPopUpIsOpen(false)}}
                />
             </span>
            </div>
          </div>
        )}
    </div>
  );
};
export default Nav;