import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightCircleIcon } from '@heroicons/react/24/solid';
import { MyPageMenuList } from '../components/ui/MenuComponent';
import { db } from '../db';
import { useLiveQuery } from 'dexie-react-hooks';
import { numberWithCommas } from '../utlis/numbers';
import NewsService from '../infrastructure/api/news';
import { HomeNewsType } from '../store/news';
import { MyPageMenuLinks, MyPageMenuServiceLinks } from '../components/ui/Nav/data';
import { BACKEND_URL } from '../store';
import useAxios from '../hooks/useAxios';
import { CampaignType } from '../store/campaign';
import BackendService from '../infrastructure/api';
import { Dialog, Transition } from '@headlessui/react';
import {convertToJapaneseDate} from '../utlis/date';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SwitchButton from '../components/ui/SwitchButton';
import { rankColors, rankImages, rankNames, rankNamesByIndex, RankType } from '../store/ranks';
import lockerSvg from '../assets/images/locker.svg';

const { user } = db;

const ContractRenewal: React.FC = () => {
  const userInterface = useLiveQuery(() => db.userInterface.get(1));
  const userData = useLiveQuery(() => db.user.get(1));
  const [hide, setHide] = useState(true);
  const [restructuringFund, setRestructuringFund] = useState(userData?.user?.restructuringFunds?.[0]);
  
  useEffect(() => {
    if (userInterface?.hideRestructuring){
      setHide(userInterface.hideRestructuring);
    }
  }, [userInterface?.hideRestructuring]);
  
  const openModal = (rfID: number) => {
    db.table('userInterface').update(1, { hideRestructuring: false });
    setRestructuringFund(userData?.user?.restructuringFunds?.find(rf => rf.id === rfID));
    setHide(false);
  };

  const closeModal = () => {
    db.table('userInterface').update(1, { hideRestructuring: true });
    setHide(true);
  };

  const restructuringFunds = userData?.user?.restructuringFunds;

  return (
    <>
      {
        restructuringFunds &&
        <div>
          {
            restructuringFunds.map((val, i) => (
              <section key={i} className="bg-white px-5 py-4 max-[450px] text-primary-dark first:mt-8">
                <div className="inline-flex w-full items-center justify-between">
                  <button className="text-base font-bold md:text-lg lg:text-xl" onClick={() => {openModal(val.id)}}>
                    {val.fund.origin.fund_name}契約更新のお知らせ
                  </button>
                </div>
              </section>
            ))
          }

          {!hide && restructuringFund && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-75 bg-primary-text">
              <div className="absolute w-[90%] lg:w-[72%]">
                <div className="relative bg-white p-10 shadow-lg">
              <span
                className='absolute -top-1 -left-1 h-20 w-20 border-t-8 border-l-8 border-primary-dark'></span>
                  <div className='flex flex-col items-center gap-8'>
                    <div>
                      <h2
                        className='mb-8 text-center text-2xl font-bold text-secondary-main'>{restructuringFund.fund.origin.fund_name}契約更新のお知らせ</h2>
                      <div className='mb-3 text-left leading-7'>
                        <p>
                          ご出資いただいております{restructuringFund.fund.origin.fund_name}が
                          <b>{convertToJapaneseDate(restructuringFund.fund.origin.operation_end)}</b>
                          に満期を迎え、ご契約が終了いたします。
                        </p>
                        <p>
                          引き続き同物件を再組成し、運用することが決定されたため、ぜひ契約更新のご検討をお願い申し上げます。
                        </p>
                        <p>
                          更新手続きにつきましては、「詳細を見る」のボタンを押すことで詳細のご確認・更新手続きについてご案内いたします。
                        </p>
                      </div>
                    </div>
                    <div
                      className='flex w-full flex-row justify-center gap-2 text-center sm:gap-8 md:flex-row md:items-end'>
                      <button onClick={closeModal}
                              className='flex w-full items-center justify-center rounded-full border-2 bg-white px-2 py-2 font-bold text-primary-dark border-primary-dark sm:w-1/2 md:w-72'>
                        保留する
                      </button>
                      <Link to={`/renewal-procedure/${restructuringFund.id}`}
                            className='flex w-full items-center justify-center rounded-full border-2 px-2 py-2 font-bold text-white bg-primary-dark border-primary-dark sm:w-1/2 md:w-72'>
                        詳細を見る
                      </Link>
                    </div>
                    <p className='font-bold text-primary-brown'>【更新期限】{restructuringFund.query_end}</p>
                  </div>
                  <span
                    className='absolute -right-1 -bottom-1 h-20 w-20 border-r-8 border-b-8 border-primary-dark'></span>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  );
};


export type NewsProp = {
  title: string;
  post_date: string;
  id?: string;
}

const News: React.FC<NewsProp> = (props) => {
  return (
    <Link to={`/news/${props.id || '1'}`} className='inline-flex flex-col px-5 max-[450px]:px-3'>
      <time className='text-sm opacity-50 text-rank-tertiaryText'>{props.post_date}</time>
      <h2 className='text-lg'>{props.title}</h2>
    </Link>
  );
};

interface ReferralCampaignProp {
  referralCode: string;
}

const ReferralCampaign: React.FC<ReferralCampaignProp> = ({referralCode}) => {
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(`${BACKEND_URL}/mypage/email/?ref_camp=${referralCode}`);
    toast.success('リンクURLをコピーしました。', {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
  
  return (
    <section className='mt-10 bg-white w-full flex flex-wrap justify-center gap-2 md:justify-between px-8 py-10 md:items-center'>
      <h3 className="text-xl font-bold text-center">トモタク紹介キャンペーンのリンクURLはこちらから</h3>
      <button type="button" onClick={copyToClipboard} className="rounded-full bg-rank-primaryAccent px-10 py-5 text-white border-rank-primaryAccent cursor-pointer font-bold text-lg">リンクURLをコピーする</button>
    </section>
  )
}

export type CampaignProp = {
  campaign: CampaignType
}
const Campaign: React.FC<CampaignProp> = ({ campaign }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (campaign.redirect_url)
    return (
      <a href={campaign.redirect_url} rel="noreferrer" target='_blank' className='inline-flex flex-col'>
        <h2
          className='sm:text-lg underline underline-offset-4 text-rank-secondaryAccentLight'>{campaign.campaign_name}</h2>
        <p className='text-sm sm:text-base'>{campaign.campaign_detail}</p>
      </a>
    );
  return (
    <>
      <div className='inline-flex flex-col cursor-pointer' onClick={() => {
        setIsOpen(true);
      }}>
        <h2
          className='sm:text-lg underline underline-offset-4 text-rank-secondaryAccentLight'>{campaign.campaign_name}</h2>
        <p className='text-sm sm:text-base'>{campaign.campaign_detail}</p>
      </div>
      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog as='div' className='relative z-10' open={isOpen} onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <img src={campaign.absolute_popup_image as string} className='h-full w-full'/>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const Home: React.FC = () => {
  const [showMain, setShowMain] = useState(true);
  const [news, setNews] = useState<HomeNewsType>(null!);
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const userData = useLiveQuery(() => user.get(1), []);
  const axios = useAxios();
  const newsService = new NewsService(axios);
  const backendService = new BackendService(axios);

  const toggleSwitch = () => {
    setShowMain(!showMain);
  }

  useEffect(() => {
    newsService.getHomeNews()
      .then((data) => {
        setNews(data);
      });
    backendService.getCampaigns()
      .then((data) => {
        setCampaigns(data);
      });
  }, []);

  return (
    <React.Fragment>
      <main className='sm:mt-4 md:mt-10'>
        <div className="container mx-auto sm:px-4">
          <section className="px-2 sm:px-0 mb-8">
            <div
              id="main"
              className="rounded-xl font-bold text-rank-tertiary h-[190px] xl:h-[250px] w-full relative [perspective:1000px]"
            >
              {/* Rotating Container */}
              <div
                className={`relative h-full w-full transition-all duration-500 [transform-style:preserve-3d] shadow-xl ${
                  showMain ? '[transform:rotateX(0)]' : '[transform:rotateX(180deg)]'
                }`}
              >
                {/* Front Side */}
                <div
                  className="absolute inset-0 h-full flex flex-col justify-between rounded-xl shadow-md [backface-visibility:hidden] p-1.5 xl:p-4 w-full"
                  style={{
                    background: 'var(--color-rank-bg)',
                  }}
                >
                  <div className="flex flex-col items-start h-full w-full">
                    <div className="flex justify-between w-full gap-8 mb-2">
                      <div className="flex flex-col xl:max-w-xl w-full gap-1 xl:gap-4">
                        <div className="inline-flex w-full items-center justify-between">
                          <img src={userData?.rank && rankImages[userData.rank]} alt="rank" className="w-20 xl:w-24" />
                          <p className="sm:text-xl">
                            <span>ID: {userData?.user?.id}</span>
                          </p>
                        </div>
                        <div className="inline-flex w-full items-center justify-between">
                          <p className="text-sm sm:text-base">資産総額</p>
                          <p className="sm:text-xl">
                            {numberWithCommas(userData?.user?.aggregateAmount) || 0}
                            <span>円</span>
                          </p>
                        </div>
                        <div className="inline-flex w-full flex-col justify-between gap-1 xl:gap-4">
                          <div className="flex flex-row justify-between">
                            <span className="text-sm sm:text-base">出資可能額</span>
                            <p className="sm:text-xl">
                              {numberWithCommas(userData?.user?.deposit) || 0}
                              <span>円</span>
                            </p>
                          </div>
                          <div className="flex flex-row justify-between">
                          <span className="text-sm sm:text-base">
                            トモタクポイント
                            <Link to="point/detail">
                              （詳細）
                            </Link>
                          </span>
                            <p className="sm:text-xl">
                              {numberWithCommas(
                                (userData?.user?.point || 0) -
                                (userData?.user?.reservedPoint || 0),
                              ) || 0}
                              pt
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="hidden xl:block flex-1 max-w-xl">
                        {
                          userData?.rank ? (
                            <RankDetail
                              rank={userData.rank}
                              nextYearRank={userData.nextYearRank}
                              nextYearRankProgressed={userData.nextYearRankProgressed}
                              rankCashbackRate={userData.rankCashbackRate}
                              untilNextRank={userData.untilNextRank}
                              rankProgress={userData.rankProgress}
                            />
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex flex-row w-full items-center justify-between border-t-2 border-rank-tertiary pt-2">
                    <p className="text-sm sm:text-base">累計配当額を見る</p>
                    <SwitchButton checked={!showMain} toggleSwitch={toggleSwitch} />
                  </div>
                </div>

                {/* Back Side */}
                <div
                  className="absolute inset-0 flex flex-col justify-between rounded-xl shadow-xl [transform:rotateX(180deg)] [backface-visibility:hidden] p-1.5 xl:p-4 w-full h-full"
                  style={{
                    background: 'var(--color-rank-bg)',
                  }}
                >
                  <div className="flex flex-col items-center justify-center gap-2 mb-2">
                    <p className="text-sm sm:text-base lg:text-xl">累計配当額</p>
                    <p className="text-2xl sm:text-3xl lg:text-5xl">
                      {numberWithCommas(userData?.user?.cumulativeDividends) || 0}
                      <span>円</span>
                    </p>
                  </div>
                  <div
                    className="flex flex-row w-full items-center justify-between border-t-2 border-rank-tertiary pt-2 sticky">
                    <p className="text-sm sm:text-base">累計配当額を見る</p>
                    <SwitchButton checked={!showMain} toggleSwitch={toggleSwitch} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="xl:hidden w-full mb-4 px-2">
            {
              userData?.rank ? (
                <RankDetail
                  rank={userData.rank}
                  nextYearRank={userData.nextYearRank}
                  nextYearRankProgressed={userData.nextYearRankProgressed}
                  rankCashbackRate={userData.rankCashbackRate}
                  untilNextRank={userData.untilNextRank}
                  rankProgress={userData.rankProgress}
                />
              ) : (
                <></>
              )
            }
          </div>

          <ContractRenewal />
          {
            !userData?.investorRegistrationCompleted &&
            <section className="px-5 py-4 bg-rank-secondary text-rank-secondaryAccent max-[450px]:px-3">
              <div className="inline-flex w-full items-center justify-between">
                <p className="text-base font-bold md:text-lg lg:text-xl">出資申込をするためには
                  <span className="md:hidden"><br /></span>
                  投資家登録が必要です</p>
                <Link to={`/investor-registration/step${userData?.registrationStep}`}
                      className="inline-flex items-center gap-1 rounded-full border-2 bg-white px-5 text-sm border-rank-secondaryText text-rank-secondaryText py-1.5 sm:gap-2 sm:px-10 sm:text-base max-[450px]:px-2">
                  <span>投資家登録を始める</span>
                  <ArrowRightCircleIcon className="h-6 w-6" />
                </Link>
              </div>
            </section>
          }
          {
            userData?.investorRegistrationCompleted && (userData.mynumberStatus && userData?.mynumberStatus !== 'declined' ||
              <section className="px-5 py-4 bg-rank-secondary text-rank-secondaryAccent max-[450px]:px-3">
                <div className="inline-flex w-full items-center justify-between">
                  <p className="text-base font-bold md:text-lg lg:text-xl">
                    マイナンバーの登録にご協力ください</p>
                  <Link to="account/edit/mynumber-registration"
                        className="inline-flex items-center gap-1 rounded-full border-2 bg-white px-5 text-sm border-rank-secondaryText text-rank-secondaryText py-1.5 sm:gap-2 sm:px-10 sm:text-base max-[450px]:px-2">
                    <span>マイナンバーを登録する</span>
                    <ArrowRightCircleIcon className="h-6 w-6" />
                  </Link>
                </div>
              </section>)
          }

          <section className="px-5 py-4 bg-rank-primary text-rank-tertiaryText max-[450px]:px-3">
            <div className="inline-flex w-full items-center justify-between">
              <p className="text-base font-bold md:text-lg lg:text-xl">
                TOMOTAQUのご利用の
                <span className="md:hidden"><br /></span>
                流れの確認はこちらから
              </p>
              <a href={`${BACKEND_URL}/guide/`}
                 className="inline-flex items-center font-normal gap-1 rounded-full border-2 bg-white px-5 text-sm border-rank-tertiaryText py-1.5 sm:gap-2 sm:px-10 sm:text-base max-[450px]:px-2">
                <span>ご利用ガイドを見る</span>
                <ArrowRightCircleIcon className="h-6 w-6" />
              </a>
            </div>
          </section>
          <section className="mt-10 bg-white py-4 sm:px-5">
            <div className="inline-flex w-full flex-col items-start gap-0 sm:flex-row md:items-center sm:gap-3">
              <div
                className="h-auto -translate-y-6 transform px-4 py-3 font-bold w-2/5 bg-rank-secondary sm:w-auto sm:translate-y-0 sm:text-lg lg:text-xl max-[395px]:w-full text-rank-secondaryAccent">
                <span className="text-base opacity-50">NEWS</span> &nbsp;
                <span className="text-base font-bold">お知らせ</span>
              </div>
              <div className="flex-1 w-full">
                <div
                  className="flex md:justify-between md:items-center w-full gap-5 md:gap-1 flex-col md:flex-row">
                  <News
                    title={news?.title || ''}
                    post_date={news?.post_date || ''}
                    id={news?.id || ''}
                  />
                  <div
                    className="flex items-center justify-center">
                    <Link to="/news"
                          className="flex max-[350px]:w-full w-[250px] bg-rank-primary sm:w-auto justify-center items-center gap-1 font-normal text-rank-tertiaryText rounded-full border-2 px-3 text-sm border-rank-tertiaryText py-1.5 sm:gap-2 sm:px-10 sm:text-base max-[450px]:px-2">
                      <span>お知らせ一覧</span>
                      <ArrowRightCircleIcon className="h-6 w-6" />
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </section>
          {userData?.investorRegistrationCompleted &&
            <ReferralCampaign referralCode={userData.user?.referralCode || ''} />}
          {
            campaigns.length > 0 &&
            <section className="mt-10">
              <h2
                className="rounded-tl-3xl px-5 py-4 font-bold bg-rank-secondary text-rank-secondaryAccent sm:text-lg lg:w-1/2 lg:text-xl">
                TOMOTAQUだけのお得なキャンペーン情報
              </h2>
              <div className="flex w-full flex-col gap-5 bg-white px-5 py-8 sm:px-10 max-[450px]:px-3">
                {
                  campaigns.map((val, i) => (
                    <Campaign
                      key={i}
                      campaign={val}
                    />
                  ))
                }
              </div>
            </section>
          }
          <section className="mt-12">
            <h2
              className="mb-5 px-5 py-4 font-bold text-rank-secondaryAccent bg-rank-secondary sm:text-lg lg:text-xl">
              マイページメニュー
            </h2>
            <div className="grid grid-cols-1 gap-5 px-3 lg:grid-cols-2">
              {
                MyPageMenuLinks.map((value, index) => <MyPageMenuList
                  className="border-rank-secondaryDark text-rank-secondaryDark" key={index} {...value} />)
              }
            </div>
          </section>

          <section className="mt-12">
            <h2
              className="mb-5 px-5 py-4 font-bold text-rank-tertiaryText bg-rank-primary sm:text-lg lg:text-xl">
              TOMOTAQUについて
            </h2>
            <div className="grid grid-cols-1 gap-5 px-3 lg:grid-cols-2">
              {
                MyPageMenuServiceLinks.map((value, index) => <MyPageMenuList
                  className="border-rank-tertiaryText text-rank-tertiaryText"
                  key={index} {...value} />)
              }
            </div>
          </section>
        </div>
        <ToastContainer />
      </main>
    </React.Fragment>);
};

interface RankDetailProps {
  rank: RankType;
  nextYearRank: number;
  nextYearRankProgressed: RankType;
  rankCashbackRate: number;
  untilNextRank: number;
  rankProgress: number;
}

const RankDetail: React.FC<RankDetailProps> = ({
                                                 rank,
                                                 nextYearRank,
                                                 nextYearRankProgressed,
                                                 rankCashbackRate,
                                                 untilNextRank,
                                                 rankProgress,
                                               }) => {
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    // Trigger animation after the component mounts
    const timeout = setTimeout(() => setProgress(rankProgress), 100);
    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [rankProgress]);
  
  return (
    <div className="w-full">
      <div className="flex flex-row justify-between items-center">
        <div>
          <div className="text-sm">現在のステータス</div>
          <div className="text-xl text-black xl:text-rank-tertiary font-bold">{rank === 'Legend' ? 'レジェンド' : rankNames[rank]}</div>
        </div>
        <div>
          <div className="text-sm">還元率</div>
          <div className="text-xl text-secondary-main font-bold">{rankCashbackRate}<span className="text-sm text-black xl:text-rank-tertiary">%</span></div>
        </div>
        <div>
          <a
            href={`${BACKEND_URL}/rank-program/`}
            className="border border-primary-dark rounded-lg text-primary-dark p-2 bg-white">
            詳細を見る
          </a>
        </div>
      </div>
      {
        rank !== 'Legend' ? (
          <>
            {
              nextYearRankProgressed === 'Diamond' ? (
                <div>
                  {rankNames['Diamond']}ステータス条件をクリアしました！
                </div>
              ) : nextYearRankProgressed !== 'Legend' ? (
                <div
                  className="flex flex-row justify-between"
                >
                  <span className="text-base">来年のステータス{rankNamesByIndex[nextYearRank]}条件クリアまで残り</span>
                  <p className="text-xl text-secondary-main">{numberWithCommas(untilNextRank)}<span
                    className="text-sm">円</span></p>
                </div>
              ) : (
                <div>{rankNames['Diamond']}ステータス条件をクリアしました！</div>
              )
            }
            <div className="w-full rounded-full bg-white p-0.5 border border-black my-2 overflow-hidden">
              <div className="h-6 rounded-full relative transition-all duration-1000 ease-in-out"
                   style={{ width: `${nextYearRankProgressed === 'Diamond' || nextYearRankProgressed === 'Legend' ? '100' : progress}%`, background: rankColors[nextYearRankProgressed].bg }}>
                <span
                  className="text-black font-bold h-7 absolute border border-black -top-0.5 rounded-xl w-14 justify-center items-center"
                  style={{
                    display: nextYearRankProgressed === 'Diamond' || nextYearRankProgressed === 'Legend' ? 'none' : 'flex',
                    background: rankColors[nextYearRankProgressed].bg,
                    left: progress < 10 ? '0' : 'auto',                     // Align to start when progress is small
                    right: progress < 10 ? 'auto' : '0',                   // Align to end when progress is larger
                }}
                >{progress}%</span>
              </div>
            </div>
            <div className="flex flex-row w-full">
              {
                Object.keys(rankNames).map((key, i) => (
                  <div
                    key={i}
                    className="flex-1 flex justify-center items-center last:after:hidden after:block after:content-[' '] after:w-5 after:h-1 after:bg-primary-light"
                  >
                    <span
                      className="text-black text-xs h-10 rounded-xl flex justify-center items-center w-full"
                      style={{ background: nextYearRank - 1 < i ? '#EBEBEB' : rankColors[key as RankType].bg }}
                    >
                      {
                        nextYearRank - 1 < i
                          ?
                          <img src={lockerSvg} className="w-3 h-3" alt="locker icon" />
                          : rankNames[key as RankType]
                      }
                    </span>
                  </div>
                ))
              }
            </div>
          </>
          ) : <></>
      }
    </div>
  );
}
export default Home;