import React from 'react';
import {Progress} from './ProgressBar';
import {FundType} from '../../store/funds';
import {numberWithCommas} from '../../utlis/numbers';
import {BACKEND_URL} from '../../store';

export const FundLayout: React.FC<FundType> = (state) => {

  return (
    <div className='relative h-full rounded-xl bg-white drop-shadow-xl'>
      <div className='flex flex-col items-center'>
        <span
          className='absolute -top-5 flex rounded-full border bg-white px-5 text-center text-base py-0.5 border-rank-primaryDark text-rank-primaryDark md:-top-3'>
          {state.current_status}</span>
      </div>
      <div className='flex h-full flex-col gap-3'>
        <figure className='overflow-hidden rounded-t-xl'>
          <img className='w-full object-cover lg:h-[224px] xl:h-[250px]' src={state.thumbnail} alt=''/>
        </figure>
        <div className='w-full overflow-hidden px-3'>
          {
            state.recruiting.filter((val) => val.finished || val.id === state.current_recruitment?.id)
              .map((val, index) => (
                <Progress
                  key={index}
                  progress={val.name === 'a' ? 100 - +((val.units_remainder / val.units) * 100).toFixed(2) : +((val.units_remainder / val.units) * 100).toFixed(2)}
                  order={index + 1}
                  color={val.finished ? 'bg-primary-grey' : 'bg-progress'}
                  label={state.recruiting.length > 1 && state.current_recruitment?.id !== val.id}
                />
              ))
          }
          <div className='mb-10 h-full divide-y divide-primary-main lg:mb-0'>
            <div className='flex items-end justify-between pt-2'>
              <p className='text-base leading-tight'>予定分配率</p>
              <p className='text-xl text-rank-primaryDark'>{state.percent}<span
                className='text-sm'> %</span></p>
            </div>
            <div className='flex items-end justify-between pt-2'>
              <p className='text-base leading-tight'>募集金額</p>
              <p className='text-xl leading-tight'>{numberWithCommas(state.amount_raised)}<span
                className='text-sm leading-tight'>万円</span></p>
            </div>
            <div className='flex items-end justify-between pt-2'>
              <p className='text-base leading-tight'>運用期間</p>
              <div className='flex flex-col items-end'>
                <p className='float-right text-xl'>{state.duration}<span
                  className='text-sm'>{state.duration_type}</span></p>
                <time
                  className='text-xs opacity-50'>{state.operation_start}~{state.operation_end}まで
                </time>
              </div>
            </div>
            <div>
              <div className='mt-4 flex flex-wrap gap-3'>
                {
                  state.tags.map((val, index) => (
                    <span
                      key={index}
                      className='rounded-full border px-5 text-xs text-primary-grey py-0.5 bg-secondary-brown border-primary-main'>
                  {val}
                </span>
                  ))
                }
              </div>
              <div className='my-5 flex w-full flex-col items-center'>
                <a
                  href={`${BACKEND_URL}/fund_list/${state.slug}/`}
                  className='rounded-sm border bg-white p-2 text-center border-rank-primaryDark text-rank-primaryDark shadow-[0px_6px] shadow-rank-primaryDark w-[80%] sm:text-lg'>詳細を見る</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};