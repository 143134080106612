import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/solid';
import { HamburgerMenuList } from '../MenuComponent';
import { hamburgerMenuLinks, hamburgerMenuServiceLinks } from './data';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../db';
import { BACKEND_URL } from '../../../store';


type HamburgerButtonProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleLink: (e: React.MouseEvent<HTMLAnchorElement>, external?: boolean) => void;
}

export const HamburgerButton: React.FC<Omit<HamburgerButtonProps, 'handleLink'>> = ({ isOpen, setIsOpen }) => {
  const genericHamburgerLine = 'h-0.5 w-8 my-1 rounded-full transition ease transform duration-300';
  return (
    <button
      className='relative z-10 flex h-14 w-14 flex-col items-center justify-center group text-primary-text'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className={`flex h-14 w-14 flex-col items-center justify-center group ${isOpen ? 'bg-primary-text md:bg-transparent rounded-full' : ''}`}>
        <div
          className={`${genericHamburgerLine} ${
            isOpen
              ? 'rotate-45 translate-y-2.5 opacity-100 group-hover:opacity-80 bg-white md:bg-primary-text'
              : 'opacity-100 group-hover:opacity-80 bg-primary-text'
          }`}
        />
        <div
          className={`${genericHamburgerLine} ${
            isOpen ? 'opacity-0' : 'opacity-100 group-hover:opacity-80 bg-primary-text'
          }`}
        />
        <div
          className={`${genericHamburgerLine} ${
            isOpen
              ? '-rotate-45 -translate-y-2.5 opacity-100 group-hover:opacity-80 bg-white md:bg-primary-text'
              : 'opacity-100 group-hover:opacity-80 bg-primary-text'
          }`}
        />
      </div>
      <small className='text-sm font-bold sm:text-xs'>{!isOpen ? 'メニュー' : ''}</small>
    </button>
  );
};

const { user } = db;

const HamburgerMenuItems: React.FC<HamburgerButtonProps> = ({ handleLink }) => {
  const userData = useLiveQuery(() => user.get(1), []);
  
  return (
    <React.Fragment>
      <div className='w-full h-32 min-[380px]:h-20 min-[380px]:mb-12 sticky top-0 bg-[#F3F9F8] z-[1]'></div>
      <div className='container mx-auto grid gap-5 px-2 sm:grid-cols-2 sm:gap-10 sm:px-4 lg:grid-cols-3 mb-20'>
        <div className='col-span-1 lg:col-span-2'>
          <div
            className='mb-5 px-5 py-3 bg-rank-secondary'
          >
            <p className='hidden text-lg font-bold text-rank-secondaryAccent sm:block'>マイページメニュー</p>
            <div
              className='flex max-[350px]:flex-col max-[350px]:items-start max-[350px]:gap-2 justify-between py-5 font-bold sm:hidden'>
              <span>
                <p className='text-rank-secondaryAccent'>ID：{userData?.user?.id}</p>
                <p className='text-rank-secondaryAccent'>{userData?.user?.fullName}</p>
              </span>
              <Link to='/account'
                    onClick={handleLink}
                    className='flex items-center rounded-full px-4 py-2 whitespace-nowrap text-black bg-white text-sm sm:text-base'>登録情報の確認／変更</Link>
            </div>
          </div>
          <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
            {
              hamburgerMenuLinks.map((value, index) => <HamburgerMenuList className='text-rank-secondaryDark border-rank-secondaryDark'
                                                                          key={index}
                                                                          onClick={(e) => {
                                                                            handleLink(e, value.external);
                                                                          }} {...value} />)
            }
          </div>
        </div>
        <div>
          <div className='mb-5 hidden bg-rank-tertiaryText sm:block'>
            <p className='px-5 py-3 text-lg font-bold text-white'>サービスページメニュー</p>
          </div>
          <div className='grid grid-cols-1 gap-5'>
            {
              hamburgerMenuServiceLinks.map((value, index) => <HamburgerMenuList className='text-rank-tertiaryText border-rank-tertiaryText'
                                                                                 onClick={(e) => {
                                                                                   handleLink(e, value.external);
                                                                                 }}
                                                                                 key={index} {...value}
              />)
            }
          </div>
        </div>
        <div className='sm:hidden grid grid-cols-1 gap-5 pb-20'>
          <HamburgerMenuList className='text-rank-secondaryDark border-rank-secondaryDark' {...hamburgerMenuLinks[7]} skip={false} />
        </div>
      </div>
    </React.Fragment>
  );
};

export const NavMenu: React.FC<Omit<HamburgerButtonProps, 'handleLink'>> = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>, external = false) => {
    if (!external) {
      e.preventDefault();
      const link = e.currentTarget.getAttribute('href');
      setIsOpen(false);
      navigate(link || '');
    }

  };

  return (
    <div className='flex w-auto items-center border-primary-brown'>
      {
        isOpen &&
        <React.Fragment>
          <div className='relative z-10 mr-10 hidden grid-cols-2 gap-2 md:grid'>
            <Link to='/' onClick={handleLink} className='rounded-full px-5 py-3 text-center text-white bg-rank-secondaryText'>マイページトップ</Link>
            <Link to={`${BACKEND_URL}/contact`}
                  className='rounded-full px-5 py-3 text-center text-white bg-primary-brown'>お問い合わせ</Link>
          </div>
          <div
            className='absolute right-0 max-[380px]:-mb-20 max-[380px]:left-0 max-[380px]:ml-auto max-[380px]:mr-auto z-10 w-52 gap-2 flex md:hidden'>
            <Link to='/'
                  onClick={handleLink}
                  className='rounded-full px-5 flex items-center text-center text-white bg-primary-dark'><HomeIcon
              className='h-5 w-5' /></Link>
            <Link to={`${BACKEND_URL}/contact`}
                  onClick={(e) => {handleLink(e, true)}}
                  className='rounded-full px-5 flex items-center py-2 text-center text-white bg-rank-tertiaryText'>お問い合わせ</Link>
          </div>
        </React.Fragment>
      }
      <div className='hidden md:block'>
        <HamburgerButton isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div
        className={`bg-[#F3F9F8] h-screen w-screen overflow-scroll fixed left-0 top-0 z-[1] ${isOpen ? 'block' : 'hidden'}`}>
        <HamburgerMenuItems isOpen={isOpen} setIsOpen={setIsOpen} handleLink={handleLink} />
      </div>
    </div>
  );
};